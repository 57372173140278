import { useState, useRef } from 'react'
import styles from './Chat.module.scss'
import IconEnter from '../../components/Icons/Enter'

export default function MessageForm(props) {
  const messageInput = useRef(null)
  const [message, setMessage] = useState()

  const handleSubmit = (e) => {
    e.preventDefault()

    if (message && message !== '') {
      props.sendMessage(message)
      messageInput.current.value = ''
      setMessage()
    }
  }
  
  return(
    <form onSubmit={handleSubmit} className={styles.messageInput}>
      <input
        ref={messageInput}
        type='text'
        onChange={e => setMessage(e.target.value)}
      />
      <button
        type='submit'
        >
        <IconEnter />
      </button>
    </form>
  )
}
import styles from './Message.module.scss'

const message = (props) => (
  <div className={styles.wrapper}>
    <div className={styles.userName}>
      {props.message.userName}
    </div>
    <div className={styles.message}>
      {props.message.text}
    </div>
  </div>
)

export default message
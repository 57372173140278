import { Fragment, useEffect, useState } from 'react';
import Welcome from './components/Screens/Welcome';
import World from './containers/World/Index'
import Chat from './containers/Chat/Index'
import PasswordCover from './PasswordCover'
import moment from 'moment'

function App() {
  const [passwordProtected, setPasswordProtected] = useState(false)
  const [scene, setScene] = useState('welcome')
  const [audioReady, setAudioReady] = useState(false);
  const [audioAuthLoaded, setAudioAuthLoaded] = useState(false)
  const [modelsReady, setModelsReady] = useState(false)

  const [linkFooterActive, setLinkFooterActive] = useState(true)

  
  const [counter, setCounter] = useState(0)
  const [progressIsActive, setProgressActive] = useState(false);
  
  const [premierEnabled, setPremierEnabled] = useState(false)
  const [premierPassed, setPremierPassed] = useState(false)
  const [days, setDays] = useState()
  const [hours, setHours] = useState()
  const [minutes, setMinutes] = useState()
  const [seconds, setSeconds] = useState()


  const [days2, setDays2] = useState()
  const [hours2, setHours2] = useState()
  const [minutes2, setMinutes2] = useState()
  const [seconds2, setSeconds2] = useState()
 
  useEffect(() => {
    if (!passwordProtected) {
      setupAudio();
    }
  }, [passwordProtected]);

  const setupAudio = () => {
    const patchbayScript = document.createElement('script');
    patchbayScript.type = 'module';
    patchbayScript.onload = function () {
      //console.log('IM script')
    };
    patchbayScript.src = "https://js.patchbay.co/v1/embed.js?key=62d5f860f767006c0ebe2896";
    document.head.appendChild(patchbayScript);

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(patchbayScript, firstScriptTag);

    document.addEventListener('audioOptOut', () => {
      enterWorld();
    });
    document.addEventListener('patchAuthMounted', () => {
      setAudioAuthLoaded(true)
    })
    document.addEventListener('audioReady', () => {
      setAudioReady(true);
    });
  }

  const confirmModelsLoaded = () => {
    setModelsReady(true)
    document.dispatchEvent(new CustomEvent('modelsLoaded'))
  }

  const enterWorld = () => {
    setScene('ground-center')
  }

  const passwordConfirmed = () => {
    setPasswordProtected(false);
  }


  // useEffect(() => {
  //   updateCountdown();
  // }, [])

  // useEffect(() => {
  //   let interval = null;
  //   if (progressIsActive) {
  //     interval = setInterval(() => {
  //       updateCountdown();
  //       setCounter(counter => counter + 1);
  //     }, 1000);
  //   } else if (!progressIsActive && counter !== 0) {
  //     clearInterval(interval);
  //   }
  //   return () => clearInterval(interval);
  // }, [progressIsActive, counter]);

  // const updateCountdown = () => {
  //   // const targetDate = moment.utc("2022-08-26 07:55")
  //   const targetDate = moment.utc("2022-08-26 08:00")
  //   const today = moment.utc()
  //   const daysCalc = targetDate.diff(today, 'days')
  //   const daysRemainder = targetDate.diff(today, 'days', true) - daysCalc
  //   const hoursCalc = 24 * daysRemainder
  //   const minutesCalc = (hoursCalc - parseInt(hoursCalc)) * 60
  //   const secondsCalc = (minutesCalc - parseInt(minutesCalc)) * 60


  //   // const endDate = moment.utc("2022-08-26 07:58:04")
  //   const endDate = moment.utc("2022-08-26 08:03:04")
  //   const daysCalc2 = endDate.diff(today, 'days')
  //   const daysRemainder2 = targetDate.diff(today, 'days', true) - daysCalc2
  //   const hoursCalc2 = 24 * daysRemainder2
  //   const minutesCalc2 = (hoursCalc2 - parseInt(hoursCalc2)) * 60
  //   const secondsCalc2 = (minutesCalc2 - parseInt(minutesCalc2)) * 60
    
    
  //   // console.log(`${days} days, ${hours} hours, ${minutes} min, ${seconds} sec`)
  //   if (parseInt(secondsCalc) >= 0) {
  //     setDays(daysCalc)
  //     setHours(parseInt(hoursCalc))
  //     setMinutes(parseInt(minutesCalc))
  //     setSeconds(parseInt(secondsCalc))


      
  //   } else {
  //     // console.log('PREMIER')
  //     setPremierEnabled(true)
  //     // wait to stop timer until premier passed
      
  //   }


  //   // console.log(`end; ${days2} days, ${hours2} hours, ${minutes2} min, ${seconds2} sec`)
  //   if (endDate.diff(today) >= 0) {

  //     setDays2(daysCalc2)
  //     setHours2(parseInt(hoursCalc2))
  //     setMinutes2(parseInt(minutesCalc2))
  //     setSeconds2(parseInt(secondsCalc2))

  //     setProgressActive(true)
  //   } else {
  //     // console.log('ENDED')
  //     setPremierPassed(true)
      
      
  //     setProgressActive(false)
  //   }
  // }

  const toggleFooter = () => {
    if (linkFooterActive) {
      setLinkFooterActive(false)
    } else {
      setLinkFooterActive(true)
    }
  }

  const showLinkFooter = () => {
    setLinkFooterActive(true)
  }
  const hideLinkFooter = () => {
    setLinkFooterActive(false)
  }

  return (
    passwordProtected
      ?
      <PasswordCover passwordConfirmed={passwordConfirmed} />
      :
      <Fragment>
        <World
          confirmModelsLoaded={confirmModelsLoaded}
          scene={scene}
          premierEnabled={premierEnabled}
          premierPassed={premierPassed}
          toggleFooter={toggleFooter}
          hideLinkFooter={hideLinkFooter}
          showLinkFooter={showLinkFooter}
        />
        
        {scene === 'welcome' &&
          <Welcome
            audioAuthLoaded={audioAuthLoaded}
            audioReady={audioReady}
            modelsReady={modelsReady}
            enterWorld={enterWorld}
          />
        }

        {scene === 'ground-center' &&
          <Chat toggleFooter={toggleFooter}/>
        }
        <div className='link-footer' data-active={linkFooterActive}>
          <a href="https://mu-se.co/WOTPOutNow" target='_blank' rel='noreferrer'>WILL OF THE PEOPLE OUT NOW</a>
          <span>\\</span>
          <a href="https://www.muse.mu/?frontpage=true" target='_blank' rel='noreferrer'>ENTER MUSE.MU</a>
        </div>

        {/* <RetroCover /> */}


        <div className='cookie-footer'>
          <a href="https://wminewmedia.com/privacy/" target="_blank" rel='noreferrer'>Privacy Policy</a>
          <a href="https://wminewmedia.com/terms-of-use/" target="_blank" rel='noreferrer'>Terms of Use</a>
          <a href="https://www.wminewmedia.com/cookies-policy/" target="_blank" rel='noreferrer'>Cookies Policy</a>
          <a className="ot-sdk-show-settings">Cookies Settings</a>
        </div>

        <div class='patchbay-player' data-id="63b8bbc6bf5c295d3c2fadd8"></div>
      </Fragment>
    
  );
}

export default App;

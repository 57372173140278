import styles from './Frame.module.scss'
import Message from './Message'

const frame = (props) => (
  <div className={styles.wrapper} data-active={props.chatOpen}>

    <img className={styles.bg} src='https://d1kbyrowkeh36j.cloudfront.net/images/wotp-chat-frame-1.png' />

    <div
      className={styles.close}
      onClick={props.toggleChat}
      >
      CLOSE
    </div>

    {props.enteredChat &&
      <div id='message-feed' className={styles.messages}>
        <div id='message-feed-inner' className={styles.messagesInner}>
          {props.messages.map((message, i) => (
            <Message key={`message_${i}`} message={message} />
          ))}
        </div>
      </div>
    }

    {props.children}
  </div>
)

export default frame
import {Fragment, useEffect, useRef, useState} from 'react'
import styles from '../../components/World/World.module.scss'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

import VideoTextures from '../../components/World/VideoTextures'
import VideoOverlay from '../../components/Elements/VideoOverlay'
import Instructions from '../../components/World/Instructions'
import VinylControls from '../../components/World/VinylControls'
import StoreModal from '../../components/World/StoreModal'

let test_key;
let debug = false
let ghostAudio;
let deviceType;

let scene, camera, renderer, modelLoader, controls, clock;

let ytIframeEnabled = false
let premierStarted = false
let premierEnded = false
let rendererCSS, youTubeScreen, ytScreenObj;
const cssScreens = []

let raycaster, pointer, navHelper;
let userNavEnabled = false

let dirLight;

let firstPlay = true;
let videosPlaying = false;

let landscape, ground;

let mask1;
let inMask = false;
const objects = []
let vinyl, vinylFront, vinylBack;
let merchView = false;
let merchReturning = false;
let merchDoorRight, merchDoorLeft;

const tvMaterials = []
const videoWallMaterials = []
let tvVideos, tourVideos, screenWallVideos;
const videoWallMeshes = []
let currentVideoId;

let maskInstance, maskDummy, maskMatrix, maskPosition
let maskTimer = 0
let maskDelay = 0
const allMasks = []
const movingMasks = []
const totalMasks = 100
const maskUpdateRate = 0.009// smaller is faster
const maskSpeed = 0.1
let masksEnabled = false;

const farBounds = 100;
const hillBoundsBack = 50;
const hillBoundsFront = -56;
const hillBoundsLeft = -76;
const hillBoundsRight = 60;


let brightenScene = false;
let orangePointLight2;

export default function Index(props) {
  const wrapperRef = useRef(null)
  const cssWrapperRef = useRef(null)
  
  const [instructionsEnabled, setInstructionsEnabled] = useState(false)
  const [vinylViewer, setVinylViewer] = useState(false)
  const [gatefoldOpen, setGatefoldOpen] = useState(false)
  const [storeModal, setStoreModal] = useState()
  const [modelsLoaded, setModelsLoaded] = useState(false)
  const [videoWallBack, setVideoWallBack] = useState(false)

  useEffect(() => {
    const currentURL = window.location;
    const currentURLParams = new URLSearchParams(currentURL.search);
    const maskKey = currentURLParams.get('masks_enabled');

    // if (maskKey) {
    //   masksEnabled = true;
    // }

    masksEnabled = true;

    if (window.Modernizr.touchevents) {
      deviceType = 'mobile'
      ytIframeEnabled = false
    } else {
      deviceType = 'desktop'
      ytIframeEnabled = true
    }

    const url = window.location;
    const urlParams = new URLSearchParams(url.search);
    test_key = urlParams.get('performance');


    initGraphics()
    initControls()
    animate()
  }, [])

  // useEffect(() => {
  //   if (props.premierEnabled) {
  //     premierStarted = true
  //     const premierMaterial = videoWallMaterials.find(x => x.id === 'video-halloween-full')

  //     if (premierMaterial) {
  //       premierMaterial.visible = 'true'
  //       setVideoWallBack(true)
  //       updateVideoWall('video-halloween-full')
  //     }
  //   }
  //   // if (props.premierPassed) {
  //   //   premierEnded = true
  //   //   if (videoWallMaterials.length > 0) {
  //   //     updateVideoWall('random')
  //   //   }  
  //   // }
  // }, [props.premierEnabled, props.premierPassed])

  useEffect(() => {
    if (props.scene === 'ground-center') {
      camera.targetPosition = camera.firstPerson
      brightenScene = true;
      if (orangePointLight2) {
        orangePointLight2.intensity = 0;
      }
      setInstructionsEnabled(true)

      document.addEventListener('pointerdown', () => {
        setInstructionsEnabled(false)
      })
    }
  }, [props.scene])

  const initGraphics = () => {
    clock = new THREE.Clock()
    scene = new THREE.Scene()
    // scene.background = new THREE.Color(0x9E633C)
    // scene.fog = new THREE.FogExp2(0x9E633C, 0.008)
    scene.fog = new THREE.FogExp2(0x361906, 0.008)

    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    camera.position.set(0, 70, 0)

    camera.targetPosition = camera.position
    camera.firstPerson = new THREE.Vector3(0, 2, 5)
    
    renderer = new THREE.WebGLRenderer({alpha: false, antialias: true})
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.outputEncoding = THREE.sRGBEncoding

    // if (!test_key) {
    //   renderer.toneMapping = THREE.ACESFilmicToneMapping;
    //   renderer.toneMappingExposure = 1.25;
    // }

    // if (!test_key) {
    //   renderer.shadowMap.enabled = true
    // }
    wrapperRef.current.appendChild(renderer.domElement)

    window.addEventListener('resize', resize, false);

    // CSS RENDERER
    rendererCSS = new CSS3DRenderer();
    rendererCSS.setSize( window.innerWidth, window.innerHeight );
    cssWrapperRef.current.appendChild( rendererCSS.domElement );


    // CONTROLS
    controls = new OrbitControls( camera, renderer.domElement );
    controls.enableZoom = false;
    controls.enablePan = false;
    controls.enableDamping = true;
    controls.rotateSpeed = - 0.40;
    controls.enabled = false

    // LIGHTS
    // const amblight = new THREE.AmbientLight(0xdb6e3e, 0.00)
    // scene.add(amblight)

    dirLight = new THREE.DirectionalLight(0xc92802, 0.06)
    dirLight.castShadow = true
    dirLight.target.position.set(0, 0, 0);
    dirLight.position.set(40, 70, -50)
    
    // if (!test_key) {
    //   dirLight.shadow.bias = -0.005;
      
    //   dirLight.shadow.camera.top = 50;
    //   dirLight.shadow.camera.bottom = -50;
    //   dirLight.shadow.camera.left = -50;
    //   dirLight.shadow.camera.right = 50;

    //   dirLight.shadow.camera.near = 10;
    //   dirLight.shadow.camera.far = 180;
    // }

    scene.add(dirLight)
    scene.add(dirLight.target);

    if (debug) {
      const helper = new THREE.DirectionalLightHelper( dirLight, 2 );
      scene.add( helper );
      scene.add( new THREE.CameraHelper( dirLight.shadow.camera ) );
    }




    const bluePointLight = new THREE.PointLight(0x2e7365, 0.8, 26, 2)
    bluePointLight.position.set( 3, 10, 20 );
    scene.add( bluePointLight );

    const orangePointLight = new THREE.PointLight(0xc92802, 2, 70, 2)
    orangePointLight.position.set( 20, 10, -8 );
    scene.add( orangePointLight );

    orangePointLight2 = new THREE.PointLight(0xc92802, 2, 50, 2)
    orangePointLight2.position.set( -43, 10, -15 );
    scene.add( orangePointLight2 );

    const whitePointLight = new THREE.PointLight(0xffffff, 0.3, 90, 2)
    whitePointLight.position.set( -10, 10, -8 );
    scene.add( whitePointLight );

    if (debug) {
      const pointLightHelperBlue = new THREE.PointLightHelper( bluePointLight, 1 );
      scene.add( pointLightHelperBlue );

      const pointLightHelperOrange = new THREE.PointLightHelper( orangePointLight, 1 );
      scene.add( pointLightHelperOrange );

      const pointLightHelperOrange2 = new THREE.PointLightHelper( orangePointLight2, 1 );
      scene.add( pointLightHelperOrange2 );

      const pointLightHelperWhite = new THREE.PointLightHelper( whitePointLight, 1 );
      scene.add( pointLightHelperWhite );
    }

    
    


    // RAYCASTER //
    raycaster = new THREE.Raycaster();
		pointer = new THREE.Vector2();


    // MODELS
    modelLoader = new GLTFLoader();
    // MIRROR MATERIAL
    let envMapLoader = new THREE.PMREMGenerator(renderer)
    let envMap;
    // 'https://d1kbyrowkeh36j.cloudfront.net/textures/sky-alt-1_comp-1.hdr'
    new RGBELoader().load('https://d1kbyrowkeh36j.cloudfront.net/textures/sky-alt-1_comp-1.hdr', hdrmap => {
      envMap = envMapLoader.fromEquirectangular(hdrmap).texture

      scene.background = envMap;
      // scene.environment = envMap;

      hdrmap.dispose()
      envMapLoader.dispose()
      
        
       
        let landscapeURL;
        if (test_key) {
          console.log('TEST')
          landscapeURL = '/models/muse-wotp_landscape_TEST.glb'
        } else {
          landscapeURL = 'https://d1kbyrowkeh36j.cloudfront.net/models/muse-wotp_landscape-20.glb'
        }
        // landscapeURL = 'https://d1kbyrowkeh36j.cloudfront.net/models/muse-wotp_landscape-20.glb'

        modelLoader.load(landscapeURL, gltf => {
          landscape = gltf.scene;
    
          ground = landscape.getObjectByName('ground')
    
          
          mask1 = landscape.getObjectByName('head-mirror-1_cracked')
          if (mask1){
            const orangePointLight = new THREE.PointLight(0x2e7365, 3, 20, 2)
            orangePointLight.position.set( mask1.position.x, 4, mask1.position.z );
            scene.add( orangePointLight );
          }

          const mask2 = landscape.getObjectByName('head-mirror-2_cracked')

          if (mask2) {
            const mask2Light = new THREE.PointLight(0x2e7365, 2.5, 10, 2)
            mask2Light.position.set( mask2.position.x + 5.5, 2, mask2.position.z - 5 );
            scene.add( mask2Light );

            const mask2LightBack = new THREE.PointLight(0x2e7365, 1.5, 10, 2)
            mask2LightBack.position.set( mask2.position.x - 2, 2, mask2.position.z + 4 );
            scene.add( mask2LightBack );
          }


          const mask3 = landscape.getObjectByName('head-mirror-3_cracked')
          if (mask3) {
            const mask3Light = new THREE.PointLight(0x2e7365, 2, 20, 2)
            mask3Light.position.set( mask3.position.x, 4, mask3.position.z );
            scene.add( mask3Light );
          }
          
          // if (debug) {
          //   const pointLightHelperBlue = new THREE.PointLightHelper( orangePointLight, 1 );
          //   scene.add( pointLightHelperBlue );
            
          //   const mask2LightHelper = new THREE.PointLightHelper( mask2Light, 1 );
          //   scene.add( mask2LightHelper );
          //   const mask2LightBackHelper = new THREE.PointLightHelper( mask2LightBack, 1 );
          //   scene.add( mask2LightBackHelper );

          //   const mask3LightHelper = new THREE.PointLightHelper( mask3Light, 1 );
          //   scene.add( mask3LightHelper );
          // }


        
          if (!test_key) {
            tvVideos = document.querySelectorAll('.tv-video')
            tourVideos = document.querySelectorAll('.tour-video')
            screenWallVideos = document.querySelectorAll('.screen-wall-video')
            // tvVideos.forEach(video => {
            //   const texture = new THREE.VideoTexture( video );
            //   const material = new THREE.MeshLambertMaterial( {map:  texture});
            //   material.userData.videoId = video.id
            //   tvMaterials.push({material, id: video.id})
            // })

            screenWallVideos.forEach(video => {
              // video.muted = true;
              const texture = new THREE.VideoTexture( video );
              const material = new THREE.MeshLambertMaterial( {map:  texture});
              material.userData.videoId = video.id

              // let premierVisibility;
              // if (premierStarted && video.id === 'video-halloween-full') {
              //   premierVisibility = 'true'
              // } else if (video.id === 'video-halloween-full') {
              //   premierVisibility = 'false'
              // } else {
              //   premierVisibility = 'true'
              // }
              video.visible = false
              videoWallMaterials.push({material, id: video.id, visible: video.dataset.visible})
            })
            tourVideos.forEach(video => {
              const texture = new THREE.VideoTexture( video );
              const material = new THREE.MeshLambertMaterial( {map:  texture});
              material.userData.videoId = video.id
              video.visible = false
              tvMaterials.push({material, id: video.id})
            })

            
            const tourScreen1 = landscape.getObjectByName('tour-screen-1')
            tourScreen1.material = tvMaterials.find(x => x.id === 'video-tour-1').material
            const tourScreen2 = landscape.getObjectByName('tour-screen-2')
            tourScreen2.material = tvMaterials.find(x => x.id === 'video-tour-2').material
          }

          const config = {
            clearcoat: 0,
            clearcoatRoughness: 0.0,
            metalness: 0.9,
            roughness: 0.0,
            envMap: envMap
          }
          const mirrorMat = new THREE.MeshPhysicalMaterial(config)

          if (!test_key) {
            youTubeScreen = landscape.getObjectByName('tv-screen_main');
          }
          
          landscape.traverse(node => {
            if ( node.isMesh ) { 
              node.castShadow = true;
              node.receiveShadow = true;

              if (node.material && node.material.name === 'mirror-reflective') {
                node.material = mirrorMat
              }

              if (node.name.includes('tv-screen_main')) {
                node.userData.interaction = 'video-controller'
                objects.push(node)
                if (!test_key) {
                  addYouTubeVideo(youTubeScreen);
                }
                node.castShadow = false;
                node.receiveShadow = false;
              } else if (node.name.includes('tv-screen')) {
                node.userData.interaction = 'video-wall'
                objects.push(node)
                videoWallMeshes.push(node)
                node.castShadow = false;
                node.receiveShadow = false;
              } else if (node.name.includes('tour-screen')) {
                node.castShadow = false;
                node.receiveShadow = false;
              } else if (node.name.includes('vinyl-package')) {
                node.userData.interaction = 'merch'
                objects.push(node)
              } else if (node.name.includes('tour-poster')) {
                node.userData.interaction = 'tour-poster'
                objects.push(node)
              }
            }
          }) 

          if (!ytIframeEnabled) {
            ghostAudio = new Audio()
            ghostAudio.addEventListener("canplaythrough", (event) => {
              // console.log('can play')
            })
          }

          if (!test_key) {
            if (premierStarted && !premierEnded) {
              setTimeout(() => {
                props.hideLinkFooter()
                setVideoWallBack(true)
                
                updateVideoWall('video-halloween-full')
              }, 2000);
            } else {
              updateVideoWall('random')
            }
          }

          // igTv = landscape.getObjectByName('ig-tv_screen')
          // addIgEmbed(igTv)

          merchDoorLeft = landscape.getObjectByName('merch-door-left')
          merchDoorRight = landscape.getObjectByName('merch-door-right')

          if (merchDoorLeft && merchDoorRight) {
            merchDoorLeft.userData.interaction = 'merch-door'
            objects.push(merchDoorLeft)
            merchDoorRight.userData.interaction = 'merch-door'
            objects.push(merchDoorRight)
          }
          


          // ALBUM INTERACTION
          vinyl = landscape.getObjectByName('vinyl-package')
          if (vinyl) {
            vinyl.startPosition = new THREE.Vector3();
            vinyl.startPosition.copy(vinyl.position);
            vinyl.targetPosition = vinyl.position;

            vinyl.targetQuaternion = new THREE.Quaternion()
            vinyl.targetQuaternion.copy(vinyl.quaternion)
            vinyl.startQuaternion = new THREE.Quaternion(0, -0.2985873818397522, 0, 0.9543823003768921);
            vinyl.openPosition = new THREE.Vector3(vinyl.position.x + 0.2, vinyl.startPosition.y + 0.5, vinyl.startPosition.z + 1)
            vinyl.closedPosition = new THREE.Vector3(vinyl.startPosition.x - 1, vinyl.startPosition.y + 0.5, vinyl.startPosition.z + 1)
            vinyl.zoomPosition = new THREE.Vector3(vinyl.startPosition.x - 1, vinyl.startPosition.y + 0.5, vinyl.startPosition.z + 1)
            
            vinylFront = landscape.getObjectByName('vinyl-package_front', true )
            vinylFront.targetQuaternion = new THREE.Quaternion()
            vinylFront.targetQuaternion.copy(vinylFront.quaternion)
            vinylFront.openQuaternion = new THREE.Quaternion(0, -0.9974949866040544, 0, 0.0707372016677029)
            vinylFront.closedQuaternion = new THREE.Quaternion(0, 0, 0, 1);
            vinylBack = vinyl.getObjectByName('vinyl-package_back')
            objects.push(vinyl)
          }
          
          
          scene.add(landscape)
    
          // MASKS
          if (masksEnabled) {
            maskDummy = new THREE.Object3D();
            maskMatrix = new THREE.Matrix4()
            maskPosition = new THREE.Vector3();
            modelLoader.load( '/models/mask.glb', gltf => {
              const maskMesh = gltf.scene.getObjectByName( 'mask' );
              const maskGeometry = maskMesh.geometry.clone();

              // const maskMaterial= new THREE.MeshPhongMaterial( { color: 0xDA0E0E } );
              // maskMaterial.side = THREE.DoubleSide;

              maskInstance = new THREE.InstancedMesh( maskGeometry, mirrorMat, totalMasks );
              scene.add(maskInstance)
              maskInstance.instanceMatrix.setUsage( THREE.DynamicDrawUsage );
              setInstancedMeshPositions(maskInstance, maskDummy, -100, 100)

              maskInstance.getMatrixAt( 0, maskMatrix );
            })
          }
    
    
          props.confirmModelsLoaded()
          setModelsLoaded(true)
        })
        
    })

    

    // const boundGeo = new THREE.BoxGeometry(5, 200, 5)
    // const boundMat = new THREE.MeshNormalMaterial()

    // const backZ = new THREE.Mesh(boundGeo, boundMat) 
    // backZ.position.set(0,100,farBounds)
    // scene.add(backZ)
    // const hillZ = new THREE.Mesh(boundGeo, boundMat) 
    // hillZ.position.set(0,100,hillBoundsBack)
    // scene.add(hillZ)

    // const frontZ = new THREE.Mesh(boundGeo, boundMat) 
    // frontZ.position.set(0,100,-farBounds)
    // scene.add(frontZ)
    // const hillZFront = new THREE.Mesh(boundGeo, new THREE.MeshBasicMaterial()) 
    // hillZFront.position.set(0,100,hillBoundsFront)
    // scene.add(hillZFront)

    // const leftX = new THREE.Mesh(boundGeo, boundMat) 
    // leftX.position.set(farBounds,100,0)
    // scene.add(leftX)
    // const hillXRight = new THREE.Mesh(boundGeo, boundMat) 
    // hillXRight.position.set(hillBoundsRight,100,0)
    // scene.add(hillXRight)

    // const nearX = new THREE.Mesh(boundGeo, boundMat) 
    // nearX.position.set(-farBounds,100,0)
    // scene.add(nearX)
    // const hillXLeft = new THREE.Mesh(boundGeo, new THREE.MeshBasicMaterial()) 
    // hillXLeft.position.set(hillBoundsLeft,100,0)
    // scene.add(hillXLeft)
    


    // const gridHelper = new THREE.GridHelper( 100, 10 );
    // scene.add( gridHelper );

    const navHelperGeo = new THREE.CircleGeometry(0.7, 32 );
    const navHelperMat = new THREE.MeshBasicMaterial( { color: 0xffffff, transparent: true, opacity: 0.7, side: THREE.DoubleSide} );
    navHelper = new THREE.Mesh( navHelperGeo, navHelperMat );
    navHelper.visible = false
    scene.add( navHelper );
  }

  

  const initControls = () => {
    wrapperRef.current.addEventListener( 'pointermove', onPointerMove );
    wrapperRef.current.addEventListener( 'mousedown', setPointerStart );
    wrapperRef.current.addEventListener( 'mouseup', handleClick );
    wrapperRef.current.addEventListener( 'touchstart', setPointerStart );
    wrapperRef.current.addEventListener( 'touchend', handleClick );

    cssWrapperRef.current.addEventListener( 'touchstart', toggleScreen );
    cssWrapperRef.current.addEventListener( 'mousedown', toggleScreen );

    const merchCover = document.querySelector('#merch-cover')
    merchCover.addEventListener( 'pointermove', merchPointerMove );
    merchCover.addEventListener( 'mousedown', merchPointerStart );
    merchCover.addEventListener( 'mouseup', merchPointerEnd );
    merchCover.addEventListener( 'touchstart', merchPointerStart );
    merchCover.addEventListener( 'touchend', merchPointerEnd );
  }
  const toggleScreen = (e) => {
    wrapperRef.current.dataset.pointer = true
  }

  const animate = () => {
    requestAnimationFrame(animate)
    
    camera.position.lerp(camera.targetPosition, 0.05)

    if (merchView) {
      controls.target = new THREE.Vector3(vinyl.position.x, 3.2, vinyl.position.z)
      camera.lookAt(vinyl.startPosition.x - 1, vinyl.startPosition.y + 0.5, vinyl.startPosition.z + 1)
    } else if (!userNavEnabled && camera.position.y < 10 && camera.position.y < camera.firstPerson.y + 1 ) {
      userNavEnabled = true
      controls.enabled = true
      navHelper.visible = true
      brightenScene = false;

      document.addEventListener('click', () => {
        if (firstPlay && !test_key) {
          startVideos()
          firstPlay = false
        }
      })
      if (!test_key) {
        tvVideos.forEach(video => {
          video.visible = true
          video.play()
        })
      }
      // scene.remove(orangePointLight2) // this creates lag
    } else if (!userNavEnabled) {
      camera.lookAt(0,0,0)
    }

    if (brightenScene && dirLight.intensity < 0.3) {
      dirLight.intensity += 0.006
    } 

    if (mask1 && !test_key) {
      const inMaskX = camera.position.x > mask1.position.x - 10 && camera.position.x < mask1.position.x + 10
      const inMaszZ = camera.position.z > mask1.position.z - 10 && camera.position.z < mask1.position.z + 10
      const mask1Overlap = inMaskX && inMaszZ
      if (mask1Overlap) {
        inMask = true
        if (premierStarted) {
          props.hideLinkFooter()
          setVideoWallBack(true)
        }
      } else {
        inMask = false
        if (videosPlaying && !premierStarted) {
          videosPlaying = false
          showVideoWallMenu()
        } else if (premierStarted) {
          props.showLinkFooter()
          setVideoWallBack(false)
        }
      }
    }
    
    let directionOffset = camera.getWorldDirection(new THREE.Vector3());
    controls.target = new THREE.Vector3(camera.position.x + directionOffset.x, camera.position.y + directionOffset.y, camera.position.z + directionOffset.z);
    controls.update();

    if (merchView && !merchReturning) {
      vinyl.position.lerp(vinyl.targetPosition, 0.09)
      vinylFront.quaternion.slerp(vinylFront.targetQuaternion, 0.09)
    } else if (!merchView && merchReturning) {
      vinyl.position.lerp(vinyl.targetPosition, 0.09)
      vinyl.quaternion.slerp(vinyl.targetQuaternion, 0.09)
    }

    if (maskInstance) {
      if (maskTimer === 0) {
        // after the timer is reset, define another waiting time
        maskDelay = Math.random() * (maskUpdateRate - 0) + 0;
      }

      maskTimer += 0.016667;
      if (maskTimer >= maskDelay) {
        // add a random mask to the array of movers
        const randomIndex = parseInt(Math.random() * (maskInstance.count - 0) + 0);
        const randDirX = Math.random() * (8 - (-8)) + (-8)
        const randDirZ = Math.random() * (8 - (-8)) + (-8)

        // const ogMask = allMasks[randomIndex]
        // if (ogMask) {
        //   movingMasks.push({index: randomIndex, dirX: randDirX, dirZ: randDirZ, posX: ogMask.posX, posZ: ogMask.posZ})
        //   maskTimer = 0;
        // }
        movingMasks.push({index: randomIndex, dirX: randDirX, dirZ: randDirZ, posX: 0, posZ: 0})
        maskTimer = 0;
      }

      updateMasks()
    }




    rendererCSS.render( scene, camera );
    renderer.render(scene, camera)
  }





  const resize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    rendererCSS.setSize(window.innerWidth, window.innerHeight);
  }



  const onPointerMove = (e) => {
    setTargetLocation(e);
  }

  const setTargetLocation = (e) => {
    pointer.x = ( e.clientX / renderer.domElement.clientWidth ) * 2 - 1;
    pointer.y = - ( e.clientY / renderer.domElement.clientHeight ) * 2 + 1;

    raycaster.setFromCamera( pointer, camera );

    // const intersectsScreen = raycaster.intersectObject(youTubeScreen)
    // if (intersectsScreen.length > 0) {
    //   wrapperRef.current.dataset.pointer = 'false'
    // }
    if (!test_key) { 
      const intersectsScreen = raycaster.intersectObject(youTubeScreen)
      if (intersectsScreen.length > 0) {
        wrapperRef.current.dataset.cursorpointer = 'true'
      }
    }
    // const intersectsIG = raycaster.intersectObject(igTv)
    // if (intersectsIG.length > 0) {
    //   wrapperRef.current.dataset.pointer = 'false'
    // }
    
    if (landscape) {
      const intersectsObjects = raycaster.intersectObjects(objects)
      const intersectsGround = raycaster.intersectObject(ground);

      if (intersectsObjects.length > 0) {
        pointer.target = intersectsObjects[ 0 ];
      } else if (intersectsGround.length > 0) {
        pointer.target = intersectsGround[ 0 ];
        
        navHelper.lookAt( pointer.target.face.normal.x, 1000, pointer.target.face.normal.z );
        navHelper.position.set(pointer.target.point.x, pointer.target.point.y + 0.3, pointer.target.point.z)
      } else {
        pointer.target = null;
      }
    }
  }

  

  let pointerStart;
  const setPointerStart = (e) => {
    pointerStart = e.touches ? e.touches[0] : e.clientX;
    setTargetLocation(e);
  }

  const handleClick = (e) => {
    if (userNavEnabled) {  
      const clientX = e.touches ? e.touches[0] : e.clientX;
      const singleClick = clientX === pointerStart && pointer.target;
      
      if (pointer.target && pointer.target.object && pointer.target.object.userData.interaction) {
        // Handle interactive objects
        if (pointer.target.object.userData.interaction === 'video-wall' && pointer.target.object.material.userData.videoId) {
          // video menu
          props.hideLinkFooter()
          setVideoWallBack(true)
          updateVideoWall(pointer.target.object.material.userData.videoId)
        } else if (pointer.target.object.userData.interaction === 'video-controller') {
          // main player
          // console.log('controller')
          // if (videosPlaying) {
          //   pauseVideos()
          // } else {
          //   resumeVideos()
          // }
        } else if (pointer.target.object.userData.interaction === 'merch') {
          viewMerch()
        } else if (pointer.target.object.name.includes('merch-door-left')) {
          setStoreModal('us')
        } else if (pointer.target.object.name.includes('merch-door-right')) {
          setStoreModal('uk')
        } else if (pointer.target.object.name.includes('tour-poster')) {
          setStoreModal('tour')
        }
      } else if (singleClick) {
        navigate();
      } else {
        // send new rotation after looking around
      }
    }
  }

  const setInstancedMeshPositions = (mesh, dummy) => {
    for ( var i = 0; i < mesh.count; i ++ ) {
      // we add 200 units of distance (the width of the section) between each.
      const randomX = Math.random() * (farBounds - (-farBounds)) + (-farBounds);
      const randomZ = Math.random() * (farBounds - (-farBounds)) + (-farBounds);

      // const picnicX = -28;
      // const picnicZ = -6;
      // if ( (randomX > (picnicX - 1.9) && randomX < (picnicX + 1.9)) && (randomZ > (picnicZ - 1.9) && randomZ < (picnicZ + 1.9))   ) {
        
      // } else {
      //   dummy.position.set(randomX, 0.4, randomZ);
      //   //dummy.rotation.y = Math.random() * (0 - -0) + -0;
      //   dummy.rotation.x = Math.random() * (0.2 - 0.01) + 0.01;
      //   const randScale = Math.random() * (0.4 - 0.3) + 0.3;
      //   dummy.scale.set(randScale, randScale, randScale);
        
      //   dummy.updateMatrix();
      //   mesh.setMatrixAt( i, dummy.matrix );
      // }
      dummy.rotation.y = Math.random() * (3.14 - 0.01) + 0.01;
      

      const posY = checkBounds(randomX, randomZ)
      
      dummy.position.set(randomX, posY, randomZ);
      allMasks.push({posX: randomX, posY: posY, posZ: randomZ})
      //dummy.rotation.y = Math.random() * (0 - -0) + -0;
      // dummy.rotation.x = Math.random() * (0.2 - 0.01) + 0.01;
      // const randScale = Math.random() * (0.4 - 0.3) + 0.3;
      // dummy.scale.set(randScale, randScale, randScale);
      
      dummy.updateMatrix();
      mesh.setMatrixAt( i, dummy.matrix );
    }
    mesh.instanceMatrix.needsUpdate = true;
  }

  const checkBounds = (posX, posZ) => {
    // console.log(posX, posZ)
    let posY;
    if (posZ < 0 && posZ < hillBoundsFront)   {
      posY = 10 // front
    } else if (posZ < -30 && posZ > hillBoundsFront)   {
      posY = 3 // front
    } else if (posZ > hillBoundsBack)   {
      posY = 13 // back
    } else if (posZ > hillBoundsBack - 40)   {
      posY = 5 // back
    } else if (posX < 0 && posX < hillBoundsLeft)   {
      posY = 13 // left
    } else if (posX < -30 && posX > hillBoundsLeft)   {
      posY = 3 // left
    } else if (posX > hillBoundsRight)   {
      posY = 10 // right
    } else if (posX > hillBoundsRight - 45)   {
      posY = 6 // right
    } else {
      posY = 0.4
    }
    // console.log(posY)
    return posY
  }

  const updateMasks = () => {
    if (maskInstance && maskMatrix) {
      movingMasks.forEach((mask, index) => {
        maskInstance.getMatrixAt( mask.index, maskMatrix );
      
        maskPosition.setFromMatrixPosition( maskMatrix ); // extract position form transformationmatrix
        if (mask.posX <= mask.dirX) {
          maskPosition.x += maskSpeed 
          mask.posX += maskSpeed
        }
        if (mask.posZ <= mask.dirZ) {
          maskPosition.z += maskSpeed 
          mask.posZ += maskSpeed
        }

        const posY = checkBounds(mask.posX, mask.posZ)
        // console.log(posY)
        maskPosition.y = posY

        if (mask.posX >= mask.dirX && mask.posZ >= mask.dirZ) {
          movingMasks.splice(index, 1)
        }
        maskMatrix.setPosition( maskPosition ); // write new positon back
        
        maskInstance.setMatrixAt( mask.index, maskMatrix );
        
        maskInstance.instanceMatrix.needsUpdate = true;
      })
    }
  }

  const addYouTubeVideo = (screen3d) => {
    // VIDEO SCREEN
    ytScreenObj = new THREE.Object3D

    const div = document.createElement( 'div' );
    div.style.width = '1920px';
    div.style.height = '1080px';
    div.style.backgroundColor = '#000';

    // div.addEventListener('mouseleave', () => {
    //   wrapperRef.current.dataset.pointer = 'true'
    // })
    div.addEventListener('mouseleave', () => {
      wrapperRef.current.dataset.cursorpointer = 'false'
    })

    const iframe = document.createElement( 'iframe' );
    iframe.id = 'yt-screen-iframe';
    iframe.style.width = '1920px';
    iframe.style.height = '1080px';
    iframe.style.border = '0px';
    iframe.allow = 'autoplay';
    iframe.src = 'https://www.youtube-nocookie.com/embed/GgyQufB1Yic?rel=0&enablejsapi=1&controls=0&disablekb=1&autoplay=0'
    
    // document.addEventListener('click', () => {
    //   document.dispatchEvent(new CustomEvent('playYTScreen'))
    // })

    div.appendChild( iframe );
    // const igFeed = document.createElement('div')
    // igFeed.classList = 'elfsight-app-363ed5cf-233c-4219-bb34-1b347c0b4ae0'
    // div.appendChild( igFeed );
    

    const screenDiv = new CSS3DObject( div );
    screenDiv.scale.set(0.005, 0.005, 0.005);
    ytScreenObj.add( screenDiv );

    // make an invisible plane for the DOM element to chop
    // clip a WebGL geometry with it.
    var material = new THREE.MeshPhongMaterial({
      opacity	: 0.15,
      color	: new THREE.Color( 0x000000 ),
      blending: THREE.NoBlending
    });

    var geometry = new THREE.BoxGeometry( 7.5, 4.21875, 0.001 )

    const cssScreen = new THREE.Mesh( geometry, material );
    cssScreen.castShadow = true;
    cssScreen.receiveShadow = true;
    ytScreenObj.lightShadowMesh = cssScreen
    cssScreens.push(cssScreen)
    ytScreenObj.add( cssScreen );
    
    const screen3dPosition = screen3d.getWorldPosition(new THREE.Vector3())
    const screen3dQuat = screen3d.getWorldQuaternion(new THREE.Quaternion())

    // screen3d.visible = false

    ytScreenObj.position.copy(screen3dPosition)
    ytScreenObj.quaternion.copy(screen3dQuat)
    scene.add(ytScreenObj)

    // Hide on initial load
    ytScreenObj.visible = false
  }

  

  const navigate = () => {
    if (pointer.target.point.x < farBounds && pointer.target.point.x > -farBounds && pointer.target.point.z < farBounds && pointer.target.point.z > -farBounds) {
      camera.targetPosition = new THREE.Vector3(pointer.target.point.x, pointer.target.point.y + 2, pointer.target.point.z);
    }
  }

  const viewMerch = () => {
    props.toggleFooter()
    merchView = true;
    controls.enabled = false
    vinyl.targetPosition = vinyl.zoomPosition
    camera.targetPosition = new THREE.Vector3(vinyl.position.x - 3, vinyl.position.y, vinyl.position.z + 3)
    setVinylViewer(true)
  }

  const openGatefold = () => {
    vinylFront.targetQuaternion = vinylFront.openQuaternion;
    vinyl.targetPosition = vinyl.openPosition;

    setGatefoldOpen(true)
  }

  const closeGatefold = () => {
    vinylFront.targetQuaternion = vinylFront.closedQuaternion;
    vinyl.targetPosition = vinyl.closedPosition;

    setGatefoldOpen(false)
  }

  const closeVinylInstructions = () => {
    // setVinylInstructions(false)
  }

  const closeAlbum = () => {
    if (gatefoldOpen) {
      closeGatefold()
      
      setTimeout(() => {
        merchView = false;
        merchReturning = true;
        controls.enabled = true
        vinyl.targetPosition = vinyl.startPosition
        vinyl.targetQuaternion = vinyl. startQuaternion
        setVinylViewer(false)
      }, 800);
    } else {
      merchView = false;
      merchReturning = true;
      controls.enabled = true
      vinyl.targetPosition = vinyl.startPosition
      vinyl.targetQuaternion = vinyl. startQuaternion
      setVinylViewer(false)
    }

    props.toggleFooter()

    setTimeout(() => {
      merchReturning = false
    }, 3000);
  }

  // Merch rotation
  var mouseDown = false,
      mouseX = 0,
      mouseY = 0;
  const merchPointerMove = (e) => {
    if (merchView) {
      if (!mouseDown) {
        return;
      }
      e.preventDefault();
      var deltaX = e.clientX - mouseX,
          deltaY = e.clientY - mouseY;
      mouseX = e.clientX;
      mouseY = e.clientY;
      rotateItem(deltaX, deltaY);
    }
  }

  const merchPointerStart = (e) => {
    if (merchView) {
      e.preventDefault();
      mouseDown = true;
      mouseX = e.clientX;
      mouseY = e.clientY;
    }
  }
  const merchPointerEnd = (e) => {
    if (merchView) {
      e.preventDefault();
      mouseDown = false;
    }
  }

  function rotateItem(deltaX, deltaY) {
    if (vinyl && merchView) {
      if (!isNaN(deltaX) && !isNaN(deltaY)) {
        vinyl.rotation.y += deltaX / 100;
      }
    }
  }

  const closeStoreModal = () => {
    setStoreModal()
  }

  const updateVideoWall = (id) => {
    const eligibleVideos = videoWallMaterials.filter(obj => {
      return obj.visible === 'true'
    })

    if (id === 'random') {
      const randYtCover = eligibleVideos[parseInt(Math.random() * ( (eligibleVideos.length - 1) - 0) + 0 )]
      youTubeScreen.material = randYtCover.material
      youTubeScreen.visible = true

      if (ytIframeEnabled) {
        ytScreenObj.visible = false
      }

      videoWallMeshes.forEach(mesh => {
        let videoMatsShuffled = eligibleVideos;
        for (let i = videoMatsShuffled.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [videoMatsShuffled[i], videoMatsShuffled[j]] = [videoMatsShuffled[j], videoMatsShuffled[i]];
        }

        if (videoMatsShuffled[0]) {
          mesh.material = videoMatsShuffled[0].material
        }
      })
      
      // screenWallVideos.forEach(video => {
      //   video.muted = true
      // })
    } else {
      const matchingMaterial = eligibleVideos.find(x => x.id === id)
      const matchingVideoElement = document.querySelector(`#${id}`)
      
      if (matchingMaterial && matchingMaterial.id !== currentVideoId) {
        // Swap wall videos
        currentVideoId = matchingMaterial.id
        
        screenWallVideos.forEach(video => {
          video.src =`https://d1kbyrowkeh36j.cloudfront.net/video/${matchingVideoElement.dataset.fullid}` 
        })

        videoWallMeshes.forEach(mesh => {
          mesh.material = matchingMaterial.material
        })

        

        // Update iframe
        if (ytIframeEnabled) {  
          const ytid = matchingVideoElement.dataset.ytid;
          const iFrame = document.querySelector('#yt-screen-iframe')
          
          if (iFrame) {
            iFrame.src = `https://www.youtube-nocookie.com/embed/${ytid}?rel=0&enablejsapi=1&controls=0&disablekb=1&autoplay=1`
          }
          // iFrame.addEventListener('click', () => {
          //   console.log('iframe')
          // })

          youTubeScreen.visible = false
        } else {
          youTubeScreen.material = matchingMaterial.material
          ghostAudio.src = `https://d1kbyrowkeh36j.cloudfront.net/audio/${matchingVideoElement.dataset.audioid}`
          ghostAudio.load()
        }

        startVideos()
      }
    }
  }

  // const pauseVideos = () => {
  //   screenWallVideos.forEach(video => {
  //     video.currentTime = 0
  //     video.pause()

  //     videosPlaying = true;
  //   })
  // }
  // const resumeVideos = () => {
  //   setTimeout(() => {
  //     // wait for yt to load
  //     screenWallVideos.forEach(video => {
  //       video.play()
  //     })

  //     videosPlaying = true;
  //   }, 1000);
  // }

  const startVideos = () => {
    if (ytIframeEnabled) {
      videoWallMeshes.forEach(mesh => {
        mesh.visible = false
      })

      setTimeout(() => {
        // wait for yt to load
        screenWallVideos.forEach(video => {
          video.pause()
          video.currentTime = 0
          video.play()
        })

        videosPlaying = true;
        ytScreenObj.visible = true;
        videoWallMeshes.forEach(mesh => {
          mesh.visible = true
        })
      }, 1000);
    } else {
      videosPlaying = true;
      screenWallVideos.forEach(video => {
        video.pause()
        video.currentTime = 0
        video.play()
      })
      // audio target play
      ghostAudio.play()
    }
  }


  const showVideoWallMenu = () => {
    if (ytIframeEnabled) {
      youTubeScreen.visible = true;
      ytScreenObj.visible = false;
    }

    props.showLinkFooter()
    setVideoWallBack(false)
    videoWallMeshes.forEach(mesh => {
      updateVideoWall('random')
    })
    screenWallVideos.forEach(video => {
      video.src =`https://d1kbyrowkeh36j.cloudfront.net/video/${video.dataset.clipid}` 
      video.pause()
      video.currentTime = 0
      video.play()
    })

    if (ytIframeEnabled) {
      const iFrame = document.querySelector('#yt-screen-iframe')
      if (iFrame) {
        iFrame.src = ''
      }
    } else {
      // audio target stop
      ghostAudio.pause()
      ghostAudio.src = ''
    }

    videosPlaying = false
  }

  return(
    <Fragment>
      <VideoTextures />
      <div ref={cssWrapperRef} className={styles.webglWrapper} data-role='css'></div>
      <div ref={wrapperRef} className={styles.webglWrapper} data-role='3d'></div>
      
      <VinylControls
        active={vinylViewer}
        gatefoldOpen={gatefoldOpen}
        closeAlbum={closeAlbum}
        openGatefold={openGatefold}
        closeGatefold={closeGatefold}
        closeVinylInstructions={closeVinylInstructions}
      />

      {storeModal &&
        <StoreModal close={closeStoreModal} store={storeModal} />
      }

      {videoWallBack &&
        <button className={`btn btn--primary ${styles.videoWallBack}`} onClick={showVideoWallMenu}>CHOOSE ANOTHER VIDEO</button>
      }

      <Instructions instructionsEnabled={instructionsEnabled}/>
    </Fragment>
  )
}
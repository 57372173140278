import styles from './Screens.module.scss';
import LoadingCover from './LoadingCover';

const welcome = (props) => (
  <div className={styles.wrapper}>
    <div className={styles.content}>

      <div className={styles.enterCta}>
        {props.audioReady
          ? <button onClick={props.enterWorld} className='btn btn--primary lead'>Enter Site</button>
          : <div class="patchbay-patch" data-id="63b8bbc6bf5c295d3c2fadd8">Loading...</div>
        }
        {!props.modelsReady &&
          <div className={styles.modelLoadingCover}>
            Loading...
          </div>
        }

        <LoadingCover ready={ props.modelsReady && (props.audioReady || props.audioAuthLoaded) }/>
      </div>
      
    </div>
  </div>
)

export default welcome;
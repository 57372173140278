import styles from './Screens.module.scss';

const loadingCover = (props) => (
  <div className={styles.loadingCover} data-ready={props.ready}>
    <div className={styles.loadingContent}>
      {props.loadingComplete
        ?
        <button onClick={props.confirmLoading} className='btn btn--primary btn--block btn--lg'>LET'S GO</button>
        :
        <div className={styles.loadingBar}>
          <div className={styles.loadingText}>
            EXPERIENCE LOADING...
          </div>
        </div>
      }
    </div>
  </div>
)
export default loadingCover


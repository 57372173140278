import { useState, useRef } from 'react'
import styles from './Chat.module.scss'

export default function SignIn(props) {
  const [name, setName] = useState()
  const [error, setError] = useState()

  const handleChange = (e) => {
    setError()
    setName(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!name || name === '') {
      setError('Please enter a username')
    } else {
      props.joinChat(name)
    }
  }

  return(
    <form onSubmit={handleSubmit} className={styles.signInWrapper}>
      <div className='input-row'>
        <label>Enter a username:</label>
        <input
          type='text'
          placeholder='Your name...'
          onChange={handleChange}
        />
      </div>
      <div className='input-row'>
        <button
          type='submit'
          className='btn btn--block btn--primary'
          >
          JOIN CHAT
        </button>
      </div>
      <span>{error}</span>
    </form>
  )
}
import styles from './Trigger.module.scss'
import IconChat from '../Icons/Chat'

const trigger = (props) => (
  <button
    onClick={props.toggleChat}
    className={styles.button}
    data-active={props.chatOpen}
  >
    <IconChat />
  </button>
)

export default trigger
import styles from './World.module.scss'


const instructions = (props) => (
  <div className={styles.instructions} data-active={props.instructionsEnabled}>
    <p>
      Tap the ground to move to a location. Drag to look around.
    </p>
  </div>
)

export default instructions;
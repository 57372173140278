import styles from '../../components/Elements/Modals.module.scss'

const instructions = (props) => (
  <div className={styles.wrapper} >
    
      {props.store === 'us' &&
        <div className={styles.content}>
          <p>
            Navigating to the US merch store. Would you like to continue?
          </p>
          <a className='btn btn--block btn--black' onClick={props.close} href='https://usstore.muse.mu/' target='_blank' rel='noreferrer'>CONTINUE</a>
          <div onClick={props.close} className={styles.close}>X CLOSE</div>
        </div>
      }

      {props.store === 'uk' &&
         <div className={styles.content}>
          <p>
            Navigating to the UK merch store. Would you like to continue?
          </p>
          <a className='btn btn--block btn--black' onClick={props.close} href='https://store.muse.mu/uk/' target='_blank' rel='noreferrer'>CONTINUE</a>
          <div onClick={props.close} className={styles.close}>X CLOSE</div>
        </div>
      }

      {props.store === 'tour' &&
        <div className={styles.content}>
          <p>
            Navigating to tour dates. Would you like to continue?
          </p>
          <a className='btn btn--block btn--black' onClick={props.close} href='https://www.muse.mu/tour' target='_blank' rel='noreferrer'>CONTINUE</a>
          <div onClick={props.close} className={styles.close}>X CLOSE</div>
        </div>
      }
    
    
  </div>
)

export default instructions;
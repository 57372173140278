import {useState} from 'react'

function PasswordCover(props) {
  const [password, setPassword] = useState()
  const [error, setError] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {password}
    fetch('/check-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      if (data.auth === 'confirmed') {
        setError('')
        props.passwordConfirmed()
      } else {
        setError('Password invalid')
      }
    })
    .catch(error => console.log(error))
  }

  return (
    <div className='password-cover'>
      <form onSubmit={handleSubmit}>
        <label>Password</label><br/>
        <input 
          type='password'
          name='password'
          autoComplete='off'
          onChange={e => {
            setPassword(e.target.value)
          }}
        />

        <p>{error}</p>

        <button type='submit'>SUBMIT</button>
      </form>
    </div>
  )
}

export default PasswordCover;
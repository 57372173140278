import styles from './VinylControls.module.scss'

const vinylControls = (props) => (
  <div className={styles.wrapper} data-active={props.active}>
    <div id='merch-cover' className={styles.merchCover} ></div>

    <button onClick={props.closeAlbum} className={styles.close}>BACK TO ROOM</button>
        
    <div className={styles.controls}>
      {props.gatefoldOpen
        ?
        <button onClick={props.closeGatefold} className='btn btn--primary'>CLOSE</button>
        :
        <button onClick={props.openGatefold} className='btn btn--primary'>OPEN</button>
      }
    </div>
  </div>
)

export default vinylControls
import { Fragment, useEffect, useRef, useState } from 'react';
import { io } from "socket.io-client";
import styles from './Chat.module.scss'
import Trigger from '../../components/Chat/Trigger'
import Frame from '../../components/Chat/Frame'
import MessageForm from './MessageForm'
import SignInForm from './SignInForm'

let socket;

export default function Index(props) {

  const [enteredChat, setEnteredChat] = useState(false)
  const [chatOpen, setChatOpen] = useState(false)
  const [userName, setUserName] = useState()
  const [messages, setMessages] = useState([{userName: '', text: "You are now entering chat... Welcome to the Will Of The People Experience" }])

  useEffect(() => {
    if (messages.length > 0) {
      autoScroll()
    }
  }, [messages])

  const joinChat = (name) => {
    initSocketIO()
    setEnteredChat(true)
    setUserName(name)
  }

  const initSocketIO = () => {
    let serverURL;
    if ( window.location.host.includes('localhost') ) {
      serverURL = 'http://wayward.ngrok.io/';
    } else if ( window.location.host.includes('heroku') ) {
      serverURL = 'https://muse-wotp.herokuapp.com/'
    } else {
      serverURL = ''
    }

    socket = io(serverURL, { transports : ['websocket'] });

    socket.on('welcome', (message) => {
      // console.log(message)
    })
    socket.on('addMessage', (message, messageName) => {
      const newMessage = {
        userName: messageName,
        text: message
      }
      setMessages(messages => [...messages, newMessage])
    })
    socket.on('guestLeft', () => {
      
    })

    socket.on('updateGuestPosition', () => {
      
    })
  }

  const sendMessage = (message) => {
    socket.emit('messageSent', message, userName )
  }

  const autoScroll = () => {
    const feed = document.querySelector('#message-feed-inner')
    if (feed) {
      const newMessage = feed.lastElementChild;
    
      const newMessageStyles = getComputedStyle(newMessage)
      const newMessageMargin = parseInt(newMessageStyles.marginBottom)
      const newMessageHeight = newMessage.offsetHeight + newMessageMargin;
    
      const visibleHeight = feed.offsetHeight;
      const containerHeight = feed.scrollHeight;
    
      const scrollOffset = feed.scrollTop + visibleHeight;
    
      if (containerHeight - newMessageHeight <= scrollOffset) {
        feed.scrollTop = feed.scrollHeight;
      }
    }
  }

  const toggleChat = () => {
    if (chatOpen) {
      props.toggleFooter()
      setChatOpen(false)
    } else {
      props.toggleFooter()
      setChatOpen(true)
    }
  }

  return (
    <div className={styles.wrapper} data-active>

      <Frame chatOpen={chatOpen} messages={messages} toggleChat={toggleChat} enteredChat={enteredChat}>
        
        {enteredChat
          ?
          <MessageForm sendMessage={sendMessage} />
          :
          <SignInForm joinChat={joinChat} />
        }
      </Frame>

      <Trigger toggleChat={toggleChat} chatOpen={chatOpen} />
    </div>
  )
}
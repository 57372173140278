import { Fragment } from "react";

const videoTextures = () => (
  <Fragment>

    {/* VIDEO WALL */}
    <video id="video-will-of-the-people-full" data-audioid='will-of-the-people_comp-full_audio.mp3' data-fullid='will-of-the-people_comp-full-1.mp4' data-clipid='will-of-the-people_clip.mp4' data-ytid='MClg7zpm6VQ' className='video3D tv-video screen-wall-video video-clip' data-visible='true' crossOrigin="anonymous" playsInline loop>
      <source src="https://d1kbyrowkeh36j.cloudfront.net/video/will-of-the-people_clip.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
    </video>
    <video id="video-compliance-full" data-audioid='compliance_comp-full_audio.mp3' data-fullid='compliance_comp-full-1.mp4' data-clipid='compliance_clip.mp4' data-ytid='QP3zRBtgvJo' className='video3D tv-video screen-wall-video video-clip' data-visible='true' crossOrigin="anonymous" playsInline loop>
      <source src="https://d1kbyrowkeh36j.cloudfront.net/video/compliance_clip.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
    </video>
    <video id="video-kill-or-be-killed-full" data-audioid='kill-or-be-killed_comp-full_audio.mp3' data-fullid='kill-or-be-killed_comp-full.mp4' data-clipid='kill-or-be-killed_clip.mp4' data-ytid='GgyQufB1Yic' className='video3D tv-video screen-wall-video video-clip' data-visible='true' crossOrigin="anonymous" playsInline loop>
      <source src="https://d1kbyrowkeh36j.cloudfront.net/video/kill-or-be-killed_clip.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
    </video>
    <video id="video-wont-stand-down-full" data-audioid='wont-stand-down_comp-full_audio.mp3' data-fullid='wont-stand-down_comp-full_X-1.mp4' data-clipid='wont-stand-down_clip.mp4' data-ytid='d55ELY17CFM' className='video3D tv-video screen-wall-video video-clip' data-visible='true' crossOrigin="anonymous" playsInline loop>
      <source src="https://d1kbyrowkeh36j.cloudfront.net/video/wont-stand-down_clip.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
    </video>

    <video id="video-euphoria-full" data-audioid='euphoria_comp-full_audio.mp3' data-fullid='euphoria_comp-full-1.mp4' data-clipid='euphoria_clip.mp4' data-ytid='zHNWEfES6XI' className='video3D tv-video screen-wall-video video-clip' data-visible='true' crossOrigin="anonymous" playsInline loop>
      <source src="https://d1kbyrowkeh36j.cloudfront.net/video/euphoria_clip.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
    </video>
    <video id="video-ghost-full" data-audioid='ghost_comp-full_audio.mp3' data-fullid='ghost_comp-full-1.mp4' data-clipid='ghosts_clip.mp4' data-ytid='XV1lQueVVxg' className='video3D tv-video screen-wall-video video-clip' data-visible='true' crossOrigin="anonymous" playsInline loop>
      <source src="https://d1kbyrowkeh36j.cloudfront.net/video/ghosts_clip.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
    </video>
    <video id="video-liberation-full" data-audioid='liberation_comp-full_audio.mp3' data-fullid='liberation_comp-full-1.mp4' data-clipid='liberation_clip.mp4' data-ytid='ZN8a8WmQ-vI' className='video3D tv-video screen-wall-video video-clip' data-visible='true' crossOrigin="anonymous" playsInline loop>
      <source src="https://d1kbyrowkeh36j.cloudfront.net/video/liberation_clip.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
    </video>
    <video id="video-verona-full" data-audioid='verona_comp-full_audio.mp3' data-fullid='verona_comp-full_X-1.mp4' data-clipid='verona_clip.mp4' data-ytid='NN1OtIJu_Bk' className='video3D tv-video screen-wall-video video-clip' data-visible='true' crossOrigin="anonymous" playsInline loop>
      <source src="https://d1kbyrowkeh36j.cloudfront.net/video/verona_clip.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
    </video>
    <video id="video-we-are-fucking-fucked-full" data-audioid='we-are-fucking-fucked_comp-full_audio.mp3' data-fullid='we-are-fucking-fucked_comp-full_X-1.mp4' data-clipid='we-are-fucking-fucked_clip.mp4' data-ytid='ac4E_UsmB1g' className='video3D tv-video screen-wall-video video-clip' data-visible='true' crossOrigin="anonymous" playsInline loop>
      <source src="https://d1kbyrowkeh36j.cloudfront.net/video/we-are-fucking-fucked_clip.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
    </video>


    <video id="video-halloween-full" data-audioid='halloween_comp-full_audio.mp3' data-fullid='halloween_comp-full-1.mp4' data-clipid='halloween_clip.mp4' data-ytid='oyu1WO0hRB0' className='video3D tv-video screen-wall-video video-clip' data-visible='true' crossOrigin="anonymous" playsInline loop>
      <source src="https://d1kbyrowkeh36j.cloudfront.net/video/halloween_clip.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
    </video>
    

    {/* TOUR */}
    <video id="video-tour-1" className='video3D tv-video tour-video' crossOrigin="anonymous" playsInline muted loop>
      <source src="https://d1kbyrowkeh36j.cloudfront.net/video/tour-1.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
    </video>
    <video id="video-tour-2" className='video3D tv-video tour-video' crossOrigin="anonymous" playsInline muted loop>
      <source src="https://d1kbyrowkeh36j.cloudfront.net/video/tour-2.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
    </video>
    
  </Fragment>
)

export default videoTextures